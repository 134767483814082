import React from 'react';
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap';
import Layout from '../layouts';
const WG = typeof window !== 'undefined' && window

export default (props) => {
  //var products = (props.pageContext.wines || []).sort((a,b) => (a.Status > b.Status) ? 1 : ((b.Status > a.Status) ? -1 : 0));
  //products = products.concat(props.pageContext.swag || []);
  const nameMap = {
    Iconic: 'Hero Series',
    Sidekick: 'Sidekick Series',
    Swag: 'Swag'
  }
  const products = props.pageContext.products || [];
    return (
      <Layout nav="shop" >
      <Row>
        <Col md={10} mdOffset={1}>
          <div className="shop-list product-item2">
            {/*<Notifications {...props} />*/}
            {products.map((products, i) => {
              return (
              <div>
              <Row><h2 style={{textAlign: 'center'}}>{nameMap[products[0].Brand]}</h2></Row>
              <Row>
              {products.map(p => <Product {...p} {...p.Wine || p} />)}
              </Row>
              </div>
              )
            })}
          </div>
        </Col>
      </Row>
      </Layout>
    )
}

const Product = (props) => {
  if (props.Status === 'Available')
  return (
  <Col xs={12} sm={4} md={4}>
    <div className="single-product" style={{padding: '10px'}}>
      <div className="product-img" style={{background: `url('${props['Product Shots'] && props['Product Shots'][0] && props['Product Shots'][0].url}')`, backgroundSize: 'auto 100%'}}>
        <Link to={props.Url}>
          <img src={props['Product Shots'] && props['Product Shots'][0] && props['Product Shots'][0].url} style={{opacity: 0}} alt={props.Name}/>
        </Link>
      </div>
      <div className="product-desc text-left send-bt">
        <h6 style={{textTransform: 'none', fontWeight: 600, textAlign: 'center'}}>{props.Name} {props.Vintage}</h6>
          {/*}<a className="btn btn-gold snipcart-add-item" href="#" style={{width: '100%'}}
            data-item-id = {props.SKU}
            data-item-metadata = {`{"type": "${props.Type}"}`}
            data-item-price = {props['Retail Price']}
            data-item-url = "https://www.pourmgmt.com/products"
            data-item-image = {props['Product Shots'] && props['Product Shots'][0].url}
            data-item-name = {props.Name + (props.Vintage ? ` ${props.Vintage}` : '')}
          >
            <button style={{marginTop: '5px', width: '100%'}}>Add to cart - ${props['Retail Price']}</button>
          </a>*/}
      </div>
    </div>
  </Col>
  )
  else
    return <Product_unavailale {...props} />
}

const Product_unavailale = (props) => (
    <Col xs={12} sm={4} md={4}>
      <div className="single-product" style={{padding: '10px'}}>
        <div className="product-img" style={{background: `url('${props['Product Shots'] && props['Product Shots'][0] && props['Product Shots'][0].url}')`, backgroundSize: 'auto 100%'}}>
          <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,.2)', right: 0}}>
          <span style={{verticalAlign: 'middle', bottom: 0, position: 'absolute', marginBottom: '30px',
              width: '100%', textAlign: 'center', color: '#fff', fontSize: '24px', fontWeight: 600}}>
              {props.Status}
          </span>
          </div>
          <Link to={props.Url}>
            <img src={props['Product Shots'] && props['Product Shots'][0] && props['Product Shots'][0].url} style={{opacity: 0}} alt={props.Name}/>
          </Link>
        </div>
        <div className="product-desc text-left send-bt">
          <h6 style={{textTransform: 'none', fontWeight: 600,  textAlign: 'center'}}>{props.Name} {props.Vintage}</h6>
          {/*}<button className="snipcart-add-item" style={{marginTop: '5px', width: '100%'}}>{props.Status} - ${props['Retail Price']}</button>*/}
        </div>
      </div>
      </Col>
  )

/*const CountBottles = () => {
  const items = WG && WG.Snipcart.api.items.all() || [];
  var count = 0;
  for (let i = 0; i < items.length; i++) {
    var metadata = JSON.parse(items[i].metadata || '{}')
    if (!metadata || metadata.type == 'wine')
      count += items[i].quantity;
  }
  return count;
}

const Notifications = (props) => {
  const cart = WG && WG.Snipcart.api.cart.get();
  const count = CountBottles();
  var shippingMessage, discountMessage;
  if (count < 4)
    shippingMessage = `Add ${4-count} more bottle(s) for half price shipping`;
  if (count < 6)
    shippingMessage = shippingMessage || `Add ${6-count} more bottle(s) for free shipping`;
  shippingMessage = shippingMessage || "You've got free shipping!";
  discountMessage = cart && cart.total < 200 ? `10% OFF ORDERS OVER $200 - Add $${200 - cart.total} for discount` : "You've got a 10% discount!";
  if (!count)
    return null;
  if (count < 2)
    return (
      <div style={styles.notification}>
        <div>
          <i style={{...styles.h3, marginRight: '20px' }} className="fa fa-truck" />
          <h3 style={{...styles.h3, }}>Minimum order of 2 bottles</h3>
        </div>
      </div>
    )
  return (
  <div style={styles.notification}>
    <div>
    <div>
      <i style={{...styles.h3, marginRight: '20px', color: count < 6 ? null : 'green' }} className="fa fa-truck" />
      <h3 style={{...styles.h3, color: count < 6 ? null : 'green'}}>{shippingMessage}</h3>
    </div>
    <div>
      <i style={{...styles.h3, marginRight: '23px', color: count < 12 ? null : 'green' }} className="fa fa-percent" />
      <h3 style={{...styles.h3, color: count < 12 ? null : 'green' }}>{discountMessage}</h3>
    </div>
    </div>
  </div>
  )
}*/

const styles = {
  notification: {
    backgroundColor: '#f7f6f7',
    borderTop: '3px solid #3f3f3f',
    listStyle: 'outside none none !important',
    margin: 0,
    marginBottom: '10px',
    //padding: '1em 2em 1em 3.5em !important',
    position: 'relative',
    width: 'auto',
    padding: '10px 20px'
  },
  h3: {
    color: '#515151',
    fontWeight: 500,
    fontSize: '16px',
    display: 'inline',
    lineHeight: '36px'
  }
}
